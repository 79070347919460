.createPage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.inputQuestionContainer {
    width: 100%;
    display: flex;
}

.inputQuestion {
    width: 250px
}

.recentMeetings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: white;
}

a, a:visited {
    text-decoration: none;
    color: rgb(96, 94, 92);
}
a:hover {
    text-decoration: underline;
}
li {
    list-style: none;
    padding: 0;
    margin: 0;
}
ul {
    padding: 0;
    margin: 0;
}
