.questionPage {
    width: 100%;
    display: flex;
    justify-content: center;
}

.questionListContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.questionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: white;
    text-align: justify;
}

.likesContainer {
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.likesItem {
    color:#0078d4;
}

.questionRead {
    background-color: greenyellow;
}

.topLeftIcon {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: xx-large;
}


.topRightIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: xx-large;
    padding: 5px;
}

a i:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}
