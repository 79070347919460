.questionPresentationContainer {
    align-items: center;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px;
    background-color: white;
    min-height: 200px;
    position: relative;
}

.joinAtText {
    text-align: center;
    font-size: 30px;
}

.centerImage {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.topQuestion {
    padding: 20px 0;
}

.topQuestionText {
    font-size: 40px;
}

.qrCode {
    text-align: center;
}

.topQuestionIcon {
    padding-top: 10px;
    margin: 0 10px;
}

.likesFullScreenContainer {
    margin-left: 20px;
    position: absolute;
    top: 0;
    right: 10px;
}

.likeIcon {
    font-size: 35px;
}

.readIcon {
    font-size: 50px;
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.readIcon:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
}

.questionText {
    padding-right: 75px;
}

.likesItemFullScreen {
    margin: 0 8px;
}
